<template>
  <div id="deviceRecord">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>

          <li>
            <span class="label">时间:</span>
            <el-date-picker
              v-model="filtrate.date"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li class="taigang">
            <span>人为抬杆：</span>{{ table.artificialNum }}个
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column label="车牌号1" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车牌号2" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateExtNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="liftType" label="抬杆类型" align="center">
        </el-table-column>
        <el-table-column
          prop="recordStatus"
          label="车辆停车状态"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="createAt" label="时间" align="center">
        </el-table-column>
        <el-table-column prop="address" label="图片" align="center">
          <template slot-scope="scope">
            <el-image
              style="width: 70px; height: 70px"
              :src="scope.row.imageUrl"
              fit="contain"
              :preview-src-list="[scope.row.imageUrl]"
            ></el-image>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
        date: null,
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
        artificialNum: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    reset() {
      this.filtrate = {
        keyword: "",
        date: null,
      };
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/channel/list/record", {
          channelId: this.$route.params.id,
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
          startTime: this.filtrate.date ? this.filtrate.date[0] : "",
          endTime: this.filtrate.date ? this.filtrate.date[1] : "",
        });
        if (res.code === 0) {
          for (let item of res.data.deviceChannelRecords.list) {
            item.createAt = this.$moment(item.createAt).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            if (!item.plateNum || !item.plateExtNum) {
              item.isSamePlateNum = true;
            } else {
              item.isSamePlateNum = item.plateNum === item.plateExtNum;
            }
          }
          this.table.tableData = res.data.deviceChannelRecords.list;
          this.table.total = res.data.deviceChannelRecords.total;
          this.table.artificialNum = res.data.artificialNum
            ? res.data.artificialNum
            : 0;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#deviceRecord {
  .table-container {
    .table-filtrate {
      ul {
        .taigang {
          font-size: 12px;
          color: hsla(26, 100%, 64%, 0.96);
          span {
            color: white;
            margin-right: 4px;
          }
        }
      }
    }
  }
  .el-table {
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          .el-table__row {
            .cell {
              .el-image {
                width: 53px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
